<template>
  <component :is="studentData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="studentData === undefined">
      <h4 class="alert-heading">Error fetching student data</h4>
      <div class="alert-body">
        No student found with this student id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-student-list' }"> Student List </b-link>
        for other students.
      </div>
    </b-alert>

    <b-tabs v-if="studentData" pills lazy v-model="tabIndex">
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <student-edit-tab-information :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social Links  -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Social Links</span>
        </template>
        <student-edit-tab-social-links :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab v-if="false">
        <template #title>
          <feather-icon icon="PaperclipIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Attributes</span>
        </template>
        <student-edit-tab-attributes :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Emails -->
      <b-tab>
        <template #title>
          <feather-icon icon="MailIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Email Addresses</span>
        </template>
        <student-edit-tab-emails :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Addresses -->
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Addresses</span>
        </template>
        <student-edit-tab-addresses :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Phone Numbers -->
      <b-tab>
        <template #title>
          <feather-icon icon="PhoneIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Phone Number</span>
        </template>
        <student-edit-tab-phone-numbers :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Notes -->
      <b-tab>
        <template #title>
          <feather-icon icon="Edit3Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Notes</span>
        </template>
        <student-edit-tab-notes :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Secret -->
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Secret</span>
        </template>
        <student-edit-tab-secret :student-data.sync="studentData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import studentStoreModule from '../studentStoreModule';
import StudentEditTabInformation from './tabs/StudentEditTabInformation.vue';
import StudentEditTabAttributes from './tabs/StudentEditTabAttributes';
import StudentEditTabAddresses from './tabs/StudentEditTabAddresses';
import StudentEditTabEmails from './tabs/StudentEditTabEmails';
import StudentEditTabPhoneNumbers from './tabs/StudentEditTabPhoneNumbers';
import StudentEditTabNotes from '@/views/apps/student/students-edit/tabs/StudentCreateTabNotes.vue';
import StudentEditTabSocialLinks from '@/views/apps/student/students-edit/tabs/StudentEditTabSocialLinks';
import StudentEditTabSecret from '@/views/apps/student/students-edit/tabs/StudentEditTabSecret';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    StudentEditTabAttributes,
    StudentEditTabInformation,
    StudentEditTabAddresses,
    StudentEditTabEmails,
    StudentEditTabPhoneNumbers,
    StudentEditTabSocialLinks,
    StudentEditTabNotes,
    StudentEditTabSecret,
  },
  data: () => {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    const { tab } = this.$route.query;

    if (tab === 'social') {
      this.tabIndex = 1;
    } else if (tab === 'notes') {
      this.tabIndex = 5;
    }
  },
  setup() {
    const studentData = ref(null);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch('app-student/fetchStudent', { id: router.currentRoute.params.id })
      .then((response) => {
        studentData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          studentData.value = undefined;
        }
      });

    return {
      studentData,
    };
  },
};
</script>

<style></style>
